import * as React from "react";
import { Box, Button, Container, Heading, Image, Link, Stack, Text, } from "@chakra-ui/react";
export default function Intro() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { maxW: "3xl" },
            React.createElement(Stack, { as: Box, textAlign: "center", spacing: { base: 8, md: 14 }, py: { base: 14, md: 24 } },
                React.createElement(Image, { src: "/static/images/neoom.svg", maxH: { base: 30, md: 50 } }),
                React.createElement(Heading, { fontWeight: 600, fontSize: { base: "2xl", sm: "4xl", md: "6xl" }, lineHeight: "120%" }, "Volle Power im neoom Portal!"),
                React.createElement(Text, { color: "gray.600", fontSize: "xl" }, "In unserem neoom Portal findest du die volle Ladung neoom: Eine Bestellm\u00F6glichkeit, deine Auftrags\u00FCbersicht, Infos & Downloads, Support & Schulungen, Unterst\u00FCtzung bei Inbetriebnahmen und Preislisten. Einfach alles, was du brauchst, um durch 360\u00B0 New-Energy-L\u00F6sungen gemeinsam mit uns deinen Kunden den Weg in eine nachhaltige, flexible & sichere Zukunft zu ebnen!"),
                React.createElement(Stack, { direction: "column", spacing: 3, align: "center", alignSelf: "center", position: "relative" },
                    React.createElement(Button, { colorScheme: "orange", bg: "orange.400", rounded: "full", px: 6, _hover: {
                            bg: "orange.500",
                        } },
                        React.createElement(Link, { href: "/portal" }, "Im Portal anmelden")),
                    React.createElement(Button, { variant: "link", colorScheme: "orange", size: "sm", pt: 5 },
                        React.createElement(Link, { href: "https://mitarbeiter.neoom.com/backoffice" }, "Als neoom Mitarbeiter anmelden")),
                    React.createElement(Button, { variant: "link", colorScheme: "orange", size: "sm" },
                        React.createElement(Link, { href: "https://neoom.com/partner", isExternal: true }, "Mehr Informationen erhalten")))))));
}
