import * as React from 'react';
import Intro from "./Intro";
import { ChakraProvider } from '@chakra-ui/react';
import theme from "../shared/Theme";
export default class App extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(ChakraProvider, { theme: theme },
            React.createElement(Intro, null)));
    }
}
