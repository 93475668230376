import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    orange: {
      25: "#FEF8F7",
      50: "#FBDCD6",
      100: "#F8C0B5",
      200: "#F5A494",
      300: "#F18874",
      400: "#EE6C53",
      500: "#EB5032",
      600: "#D03A1D",
      700: "#A03622",
      800: "#A03622",
      900: "#A03622",
    },
  },
  styles: {
    global: {
      body: {
        // backgroundImage: "/static/images/background.png",
        backgroundColor: "#f2f2f2",
        backgroundSize: "cover",
        minHeight: "100vh",
        fontFamily: '"TitilliumWeb-Regular", Arial, sans-serif',
        fontSize: "16px",
      },
      "h1, h2, h3, h4": {
        fontFamily: '"TitilliumWeb-Bold", Arial, sans-serif',
      },
      a: {
        color: "orange.500",
      },
    },
  },
});

export default theme;
